import { v4 as uuidv4 } from 'uuid';

import {
  createRosOptions,
  updateOptions
} from '@services/youtube-platform.service';

export const insertQuestion = (existingQuestions, newQuestion, position) => {
  const questions = [...existingQuestions];

  const index = (position === undefined || position === null) ? questions.length : Math.max(0, position);

  questions.splice(index, 0, newQuestion);

  for (let i = 0; i < questions.length; i++) {
    questions[i].questionNumber = i + 1;
  }

  return questions;
};

export const TOOLTIP_CONTENT_MAP = {
  pollWithOptions: 'Your audience can vote for multiple options in the poll.',
  freeForm: 'Your audience can vote for multiple options in the poll.',
  map: 'Your audience can enter multiple locations in the chat.'
};

export const SHOW_USERNAME_TOOLTIP_CONTENT_MAP = {
  talkingTiles: 'Each tile will show the username of the commenter.',
  transientThought: 'Each bubble will show the username of the commenter.',
  'Quick Questions': 'Each question will show the username of the commenter.'
};

export const defaultPollOptions = ['Option A', 'Option B'].map((title, index) => ({
  key: uuidv4(),
  option_number: index + 1,
  body: title
}));

export const defaultWheelOptions = ['Option A', 'Option B'].map((title, index) => ({
  key: uuidv4(),
  option_number: index + 1,
  body: title
}));

export const defaultRatingPollsOptions = ['1', '2', '3', '4', '5'].map((title, index) => ({
  key: uuidv4(),
  option_number: index + 1,
  body: title
}));

export const defaultMultpleChoiceOptions = ['Option A', 'Option B'].map((title, index) => ({
  key: uuidv4(),
  option_number: index + 1,
  body: title,
  checked: false,
}));

export const handleSaveOptions = async (featureType, options, questionId) => {
  if (featureType === 'pollWithOptions' || featureType === 'wheel') {
    options.forEach((opt, i) => {
      if (i === 0) opt.body = opt.body || 'Option A';
      if (i === 1) opt.body = opt.body || 'Option B';
    });
  }

  const saveOptions = options
    .filter(option => option.body.trim().length > 0)
    .filter((option, index, self) =>
      index === self.findIndex(o => o.body.trim() === option.body.trim())
    )
    .map((option, index) => ({
      body: option.body,
      option_number: index + 1,
      question_id: questionId,
      ...(featureType === 'multipleChoice' && { checked: option.checked })
    }));

  await createRosOptions(saveOptions);
};

export const linkCategories = {
  all: {
    name: 'All',
    img: '/images/linkLibrary/HyperLink.svg'
  },
  linkedin: {
    name: 'LinkedIn',
    img: '/images/linkLibrary/linkdinSelect.svg'
  },
  instagram: {
    name: 'Instagram',
    img: '/images/linkLibrary/instagramSelect.svg'
  },
  twitter: {
    name: 'X',
    img: '/images/linkLibrary/twitterSelect.svg'
  },
  spotify: {
    name: 'Spotify',
    img: '/images/linkLibrary/spotifySelect.svg'
  },
  amazon: {
    name: 'Amazon',
    img: '/images/linkLibrary/amazonSelect.svg'
  },
  youtube: {
    name: 'YouTube',
    img: '/images/linkLibrary/youtubeSelect.svg'
  },
  twitch: {
    name: 'Twitch',
    img: '/images/linkLibrary/twitchSelect.svg'
  },
  facebook: {
    name: 'Facebook',
    img: '/images/linkLibrary/fbSelect.svg'
  },
  email: {
    name: 'Email',
    img: '/images/linkLibrary/emailSelect.svg'
  }
};

export const getImage = (setting) => {
  if (!setting.LinkType) return { url: linkCategories.all.img, name: linkCategories.all.name };
  const obj = linkCategories[setting.LinkType.toLowerCase()];
  return {
    url: obj?.img || '',
    name: obj?.name || ''
  };
};

export const convertToPascalCase = (text) => {
  if (!text) return '';
  const lower = text.toLowerCase();
  return lower.charAt(0).toUpperCase() + lower.slice(1);
};

export const playbackOptions = {
  slower: 'Slower',
  default: 'Normal',
  faster: 'Faster'
};

export const OPTIONS_LABEL = [
  {
    key: 'number',
    label: '1, 2, 3...',
  },
  {
    key: 'letter',
    label: 'A, B, C...',
  },
];

export const mapContinents = [
  { name: 'World', value: 'world' },
  { name: 'Asia', value: 'asia' },
  { name: 'Africa', value: 'africa' },
  { name: 'Australia', value: 'australia' },
  { name: 'Europe', value: 'europe' },
  { name: 'North America', value: 'north-america' },
  { name: 'South America', value: 'south-america' }
];

export const getOptionName = (setting) => {
  const option = mapContinents.find(
    (option) => option.value === setting.DefaultView
  );
  return option?.name || 'World';
};

export const handleUpdateOptions = async (featureId, featureType, options, questionId) => {
  if (featureType === 'pollWithOptions' || featureType === 'wheel') {
    options.forEach((opt, i) => {
      if (i === 0) opt.body = opt.body || 'Option A';
      if (i === 1) opt.body = opt.body || 'Option B';
    });
  }

  const saveOptions = options
    .filter(option => option.body.trim().length > 0)
    .filter((option, index, self) =>
      index === self.findIndex(o => o.body.trim() === option.body.trim())
    )
    .map((option, index) => ({
      id: option.id || uuidv4(),
      body: option.body,
      option_number: index + 1,
      question_id: questionId,
      ...(featureType === 'multipleChoice' && { checked: option.checked }),
    }));

  return await updateOptions(questionId, { engagementId: featureId, engagementType: featureType, options: saveOptions });
};

export const rgbToHex = (rgb) => {
  const match = rgb.match(/\d+/g);
  if (!match || match.length < 3) return rgb;

  let [r, g, b] = match.map(Number);

  return `#${[r, g, b]
    .map((x) => x.toString(16).padStart(2, '0'))
    .join('')
    .toUpperCase()}`;
};

export const parseColorToRgba = (color) => {
  if (!color) return { r: 0, g: 0, b: 0, a: 255 };

  if (color.startsWith('#')) {
    let hex = color.slice(1);
    if (hex.length === 3) hex = hex.split('').map((c) => c + c).join('');
    if (hex.length === 6) hex += 'FF'; // Add full alpha if missing
    const [r, g, b, a] = hex.match(/.{2}/g).map((h) => parseInt(h, 16));
    return { r, g, b, a };
  }

  if (color.startsWith('rgb')) {
    const [r, g, b, a = 1] = color.match(/\d+(\.\d+)?/g).map(Number);
    return { r, g, b, a: Math.round(a * 255) };
  }

  return { r: 0, g: 0, b: 0, a: 255 };
};

export const parseColorToHex = (color) => {
  if (!color) return '#000000';

  if (color.startsWith('#')) {
    return color;
  }

  const match = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)/);
  if (match) {
    const r = parseInt(match[1], 10);
    const g = parseInt(match[2], 10);
    const b = parseInt(match[3], 10);

    return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
  }
  return '#000000';
};

export const lightenColor = (rgba, percentage) => {
  const lighten = (value) => Math.min(255, Math.round(value + (255 - value) * (percentage / 100)));
  return { r: lighten(rgba.r), g: lighten(rgba.g), b: lighten(rgba.b), a: rgba.a };
};

export const darkenColor = (rgba, percentage) => {
  const darken = (value) => Math.max(0, Math.round(value - value * (percentage / 100)));
  return { r: darken(rgba.r), g: darken(rgba.g), b: darken(rgba.b), a: rgba.a };
};

export const mapStylesMap = {
  Standard: {
    water: [
      ['water', 'fill-color'],
      ['water-shadow', 'fill-color'],
      ['water-depth', 'fill-color']],
    land: [
      ['land', 'background-color'],
      ['landcover', 'fill-color']
    ]
  },
  Light: {
    water: [
      ['water', 'fill-color'],
      ['water-shadow', 'fill-color'],
      ['water-depth', 'fill-color']
    ],
    land: [
      ['land', 'background-color'],
      ['landcover', 'fill-color']
    ]
  },
  Dark: {
    water: [
      ['water', 'fill-color'],
      ['water-shadow', 'fill-color'],
      ['water-depth', 'fill-color']
    ],
    land: [
      ['land', 'background-color'],
      ['landcover', 'fill-color']
    ]
  },
  Sky: {
    water: [
      ['water', 'fill-color'],
      ['water-shadow', 'fill-color'],
      ['water-depth', 'fill-color']],
    land: [
      ['land', 'background-color'],
      ['landcover', 'fill-color']
    ]
  },
  Globe: {
    water: [
      ['water', 'fill-color'],
      ['water-shadow', 'fill-color'],
      ['water-depth', 'fill-color']
    ],
    land: [
      ['land', 'background-color'],
      ['landcover', 'fill-color']
    ]
  },
  Comic: {
    water: [
      ['water fill (white)', 'fill-color']
    ],
    land: [
      ['background color (white)', 'background-color']
    ]
  },
  Pencil: {
    land: [
      ['background', 'background-color']
    ]
  },
  Blueprint: {
    water: [
      ['water-custom', 'fill-color']
    ],
    land: [
      ['land', 'background-color']
    ]
  },
  Bright: {
    water: [
      ['water-depth', 'fill-color']
    ],
    land: [
      ['land', 'background-color']
    ]
  },
  'Bright (Globe)': {
    water: [
      ['water-depth', 'fill-color']
    ],
    land: [
      ['land', 'background-color']
    ]
  },
};
