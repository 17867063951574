import React from 'react';

import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

import Switch from '@atoms/new/switch';
import Tooltip from '@components/Presentation/atoms/Tooltip';

const SettingSwitch = ({ label, tooltipContent, settingKey, settingValue, onChangeHandler }) => (
  <div className="flex">
    <div className="flex-1 text-sm">
      {label} &nbsp;
      <Tooltip content={tooltipContent}>
        <HelpRoundedIcon fontSize="small" color="disabled" className='dark:text-grey-400' />
      </Tooltip>
    </div>
    <Switch
      disableAnimation
      onValueChange={(e) =>
        onChangeHandler(settingKey, !settingValue)
      }
      isSelected={!!settingValue}
    />
  </div>
);

export default SettingSwitch;
