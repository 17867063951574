import styled from 'styled-components';
import {
  Select as NextUISelect,
  SelectItem as NextUISelectItem,
  SelectSection as NextUISelectSection,
  extendVariants
} from '@nextui-org/react';

export const Select = styled(NextUISelect).attrs(props => ({
  classNames: {
    trigger: 'h-9.5 min-h-9.5 max-h-9.5 rounded-md border border-grey-300 dark:border-grey-700 bg-white dark:bg-transparent data-[hover=true]:bg-white data-[selectable=true]:focus:bg-white data-[hover=true]:border-grey-400 data-[hover=true]:dark:bg-grey-800 data-[selectable=true]:focus:dark:bg-grey-800 data-[hover=true]:dark:border-grey-700 shadow-none',
    ...props.classNames
  },
  listboxProps: {
    className: 'px-0 ',
    itemClasses: {
      base: [
        'rounded-none',
        'py-1.5',
        'px-4',
        'text-grey-900',
        'dark:text-grey-200',
        'data-[hover=true]:bg-grey-100',
        'data-[selectable=true]:focus:bg-grey-100',
        'data-[hover=true]:dark:bg-grey-800',
        'data-[selectable=true]:dark:focus:bg-grey-800'
      ],
    },
    ...props.listboxProps
  },
  popoverProps: {
    classNames: {
      base: 'rounded-md',
      content: 'p-0 bg-white dark:bg-grey-900 rounded-md',
    },
  },
}))``;

export const SelectItem = extendVariants(NextUISelectItem, {});

export const SelectSection = styled(NextUISelectSection).attrs(props => ({
  classNames: {
    heading: 'uppercase font-medium text-grey-500 px-4 py-2 text-sm',
  },
}))``;

export default Select;
