import { forwardRef } from '@nextui-org/react';
import {
  Switch as NextUISwitch,
  type SwitchProps as NextUISwitchProps,
} from '@nextui-org/switch';

interface SwitchProps extends NextUISwitchProps {}

export const Switch = forwardRef(
  ({ size = 'sm', color = 'success', ...props }: SwitchProps, ref) => {
    return (
      <NextUISwitch
        classNames={{
          wrapper: 'h-5 w-9 p-0.5 bg-grey-400 dark:bg-grey-700',
        }}
        size={size}
        color={color}
        ref={ref}
        {...props}
      />
    );
  }
);

export default Switch;
