import type React from 'react';

interface ThemeCardBaseProps {
  isSelected?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  noCursor?: boolean;
  height?: string;
  minWidth?: string;
  isDisabled?: boolean;
}
export default function ThemeCardBase({
  isSelected = false,
  onClick,
  className = '',
  children,
  height = 'h-[178px]',
  minWidth = '',
  isDisabled = false
}: ThemeCardBaseProps) {
  return (
    <button
      className={`${isDisabled ? 'opacity-50 cursor-not-allowed' : ''} ${minWidth} ${className} ${isSelected ? 'border-red-500' : 'border-grey-200 dark:border-grey-800'} ${onClick ? '' : 'cursor-default'} ${height} bg-grey-0 dark:bg-grey-800/60 border-1  grid place-items-center rounded-md overflow-hidden transition-colors`}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
}
