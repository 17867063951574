import { Skeleton } from '@nextui-org/skeleton';
import type React from 'react';
import { useEffect, useState, useRef } from 'react';

export default function ImageLoader({
  src,
  className = 'size-full object-contain',
  alt = '',
  ...props
}: React.ImgHTMLAttributes<HTMLImageElement>) {
  const [isLoading, setIsLoading] = useState(true);
  const imgRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    setIsLoading(true); // Reset loading state on src change

    // Check if the image is already cached (this helps when it's fetched from cache)
    if (imgRef.current?.complete && imgRef.current.naturalWidth !== 0) {
      setIsLoading(false); // If the image is already loaded, hide the skeleton
    } else {
      setTimeout(() => {
        setIsLoading(false); // hide after 30s
      }, 30000);
    }
  }, [src]);

  const hideLoadingScreen = () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <Skeleton className={`absolute ${className}`}>
          <div className="size-full rounded-lg bg-default-300 dark:bg-grey-800"></div>
        </Skeleton>
      )}
      <img
        {...props}
        ref={imgRef}
        src={src}
        className={className}
        alt={alt}
        onLoad={hideLoadingScreen}
        onError={hideLoadingScreen}
      />
    </>
  );
}
